import React, { useEffect } from "react";
import { navigate } from "gatsby";
import _get from "lodash.get";

import * as S from "app/components/GalleryModalList.style";

import GalleryImage from "app/components/GalleryImage";
import GalleryItem from "app/components/GalleryItem";
import Pager from "app/components/Pager";

import useGalleryItemData from "app/hooks/useGalleryItemData";
import useGalleryData from "app/hooks/useGalleryData";
import useNavData from "app/hooks/useNav";

const GalleryItemPage = ({ pageContext }) => {
  const { setNavItems, setBackLink } = useNavData();
  const galleries = useGalleryData();
  const entryId = _get(pageContext, "id");

  const parent = galleries.find(({ gallery }) =>
    gallery.find(({ id }) => entryId === id)
  );

  const entry = useGalleryItemData(entryId);

  const items = _get(parent, "gallery") || [];
  const total = items.length;

  const count = total ? items.findIndex(({ id }) => entryId === id) : 0;
  const pad = total > 99 ? 3 : 2;
  const changeItem = (uri) => (uri ? () => navigate(`/${uri}/`) : undefined);
  const galleryLink = _get(parent, "uri");

  useEffect(() => {
    setNavItems(undefined);
    setBackLink({
      uri: `/${galleryLink}/`,
    });
  }, [setNavItems, setBackLink, galleryLink]);

  return (
    <div css={S.galleryItemPage}>
      <div css={[S.container, S.mxauto, S.galleryItemRoot]}>
        <div css={[S.container, S.mxauto, S.imageArea]}>
          <div css={[S.imageBox, S.flexCenterAll, S.relative]}>
            <GalleryImage
              css={[S.flexCenterAll, S.image]}
              image={_get(entry, "fullImage")}
            />
            <div css={S.pagerBox}>
              <Pager
                vert={false}
                onNext={changeItem(_get(items, `${[count + 1]}.uri`))}
                onPrev={changeItem(_get(items, `${[count - 1]}.uri`))}
              />
            </div>
          </div>
        </div>
        <div
          id="gallery-content"
          css={[S.contentArea, S.flex, S.flexCol, S.justifyCenter, S.w100]}
        >
          <GalleryItem
            id={entryId}
            count={`${count + 1}`.padStart(pad, "0")}
            countTotal={total ? `${total}`.padStart(pad, "0") : undefined}
            total={total}
          />
        </div>
      </div>
    </div>
  );
};

export default GalleryItemPage;
